import React from 'react'
import Image from 'components/image/image'
import './side-pages.sass'

const LayoutSidePages = ({ children, background }) => {
  return (
    <div className={`LayoutSidePages ${background}`}>

      <Image image_url="layout/bg-nav-stripes.svg" alt="background hexagon stripes pattern" className="LayoutSidePages__background--stripes"/>

      <div className="container-fluid">
        <Image image_url="layout/bg-diamond.svg" alt="background diamond pattern empty big" className="LayoutSidePages__background--big"/>
        <Image image_url="layout/bg-diamond.svg" alt="background diamond pattern empty small" className="LayoutSidePages__background--small"/>
        {children}
      </div>

    </div>
  )
}

export default LayoutSidePages