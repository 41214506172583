import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo/seo'
import LayoutSidePages from 'components/layout/side-pages'
import ClientList from "components/client-list/client-list";
import DynamicBlocks from 'components/dynamic-blocks/dynamic-blocks'
import GetInTouch from 'components/get-in-touch/get-in-touch'
import './ai-consulting.sass'

const AIConsulting = ({ data }) => {
  const d = data.aiConsultingYaml

  return (
    <Layout bodyClass="k-reverse-header">
      <SEO
        title="Getting started with Kortical | Build 1000’s ML models in code, fast"
        metaTitle={d.meta.title}
        keywords={d.meta.keywords}
        description={d.meta.description}
        image={d.meta.image} />
      <div className="AIConsultancy">
          <div className="z-index-top">
            <LayoutSidePages background="k-bg--grey">
              <h1 className="h1--new pb-5 mb-0">AI Consulting</h1>
            </LayoutSidePages>
          </div>
          <DynamicBlocks data={d.sections.slice(0, 1)} />
          <ClientList data={d.clientList} sectionId="AIConsultancy__clients" has_text={false} has_title={false} />
          <DynamicBlocks data={d.sections.slice(1)} />
      </div>
      <GetInTouch/>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    aiConsultingYaml {
      meta {
        title
        keywords
        image
        description
      }
      sections {
        component
        classes
        content
      }
      clientList {
        name
        logo
        link
      }
    }
  }
`

export default AIConsulting